import _objectSpread from "/var/jenkins_home/workspace/frontend-web-shop/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { afterTableList, saleTitleList } from "./after.mock";
export default {
  data: function data() {
    return {
      afterTableList: afterTableList,
      saleTitleList: saleTitleList,
      dataList: [],
      large: "",
      //搜索输入框值
      activeIdx: 0,
      ASC: true,
      total: null,
      current: 1,
      // 页码
      perPage: 10,
      rangeBefore: 3,
      rangeAfter: 1,
      status: "",
      isShowSearch: false,
      orderAfterLineVoList: []
    };
  },
  filters: {
    statusName: function statusName(type) {
      var statusObj = {
        1: "待审核",
        2: "已审核",
        3: "已拒绝"
      };
      return statusObj[type];
    }
  },
  created: function created() {
    this.orderAfterLineList();
  },
  components: {},
  methods: {
    changeShow: function changeShow() {
      this.isShowSearch = !this.isShowSearch;
    },
    onSearch: function onSearch() {},
    changeSale: function changeSale(idx) {
      this.activeIdx = idx;
      switch (idx) {
        case 0:
          this.status = "";
          this.current = 1;
          return this.orderAfterLineList();
        case 1:
          this.status = "1";
          this.current = 1;
          return this.orderAfterLineList();
        case 2:
          this.status = "2";
          this.current = 1;
          return this.orderAfterLineList();
        case 3:
          this.status = "3";
          this.current = 1;
          return this.orderAfterLineList();
      }
    },
    // 列表
    orderAfterLineList: function orderAfterLineList() {
      var _this = this;
      var url = "/pjyy-deepexi-order-center/api/v1/order/OcOrderAfter/returnGoodsList";
      var data = {
        page: this.current,
        pageSize: this.perPage,
        status: this.status
      };
      this.$getHttpClient().post(url, data).then(function (res) {
        _this.total = res.data.data.total;
        _this.perPage = res.data.data.pageSize;
        _this.dataList = res.data.data.data;
        _this.dataList.forEach(function (item) {
          item.arr = [];
          item.orderAfterLineVoList.forEach(function (ele) {
            ele.orderAfterLineBatchVoList.forEach(function (el) {
              item.arr.push(_objectSpread({
                produceFactory: ele.produceFactory,
                productName: ele.productName,
                productPhoto: ele.productPhoto
              }, el));
            });
          });
        });
      });
    },
    // 跳转详情
    handleSalesDetail: function handleSalesDetail(item) {
      this.$router.push({
        name: "salesDetail",
        params: {
          status: "2",
          orderNumber: item.id
        }
      });
    },
    handleChange: function handleChange(val) {
      this.current = val;
      this.orderAfterLineList();
    }
  },
  updated: function updated() {}
};