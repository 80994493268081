export var afterTableList = ["全部退货单", "待审核", "已审核", "已拒绝"];
export var saleTitleList = ["商品信息", "批号", "数量", "状态", "退款金额"];
export var dataList = [{
  tcode: 'pj299292292929',
  time: '2020-01-25',
  dcode: 'oj394939439943',
  shopList: [{
    // url: require("./images/5.png"),
    Urlname: '水果沙拉酱水果沙拉酱水果沙拉酱',
    num: 3,
    sum: 12,
    name: '待支付',
    money: '128.00',
    moneyName: '线上支付',
    status: 0
  }, {
    // url: require("./images/5.png"),
    Urlname: '水果沙拉酱水果沙拉酱水果沙拉酱',
    num: 3,
    sum: 12,
    name: '待支付',
    money: '128.00',
    moneyName: '线上支付',
    status: 0
  }]
}, {
  tcode: 'pj299292292929',
  time: '2020-01-25',
  dcode: 'oj394939439943',
  shopList: [{
    // url: require("./images/5.png"),
    Urlname: '水果沙拉酱水果沙拉酱水果沙拉酱',
    num: 3,
    sum: 12,
    name: '待支付',
    money: '128.00',
    moneyName: '线上支付',
    status: 0
  }]
}, {
  tcode: 'pj299292292929',
  time: '2020-01-25',
  dcode: 'oj394939439943',
  shopList: [{
    // url: require("./images/5.png"),
    Urlname: '水果沙拉酱水果沙拉酱水果沙拉酱',
    num: 3,
    sum: 12,
    name: '待支付',
    money: '128.00',
    moneyName: '线上支付',
    status: 0
  }]
}];